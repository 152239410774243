
import { defineComponent } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'certificate-update',
  components: {
    Datatable,
  },
  data() {
    return {
      batch: {
        entity_id: '',
        tranche_id: '',
        institute_id: '',
        course_id: '',
        batch_id: '',
      },
      api_url: '',
      entityInfos: [],
      instituteList: [],
      courseList: [],
      checkedData: [],
      traineeList: [] as any,
      batchList: [],
      tranches_info: [],
      showDataTable: false,
      componentKey: 0,
      loading: false,
      load: false,
      courseInfoData: false,
      showCourseNotice: false,
      certificationdate: '' as any,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;

    this.emitter.on('certification_updated', async () => {
      this.traineeList = [];
      this.getTraineeList();
      this.componentKey += 1;
    });
    await this.getEntityInfos();
    await this.getTranches();
    this.generatecurrentdate();
  },
  methods: {
    generatecurrentdate() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();

      this.certificationdate = yyyy + '-' + mm + '-' + dd;
    },
    async formSubmit() {
      if (this.checkedData.length > 0) {
        this.load = true;
        let data = JSON.stringify(Object.assign({}, this.checkedData));
        let formdata = new FormData();
        formdata.set('certificates', data);
        formdata.set('certificateDate', this.certificationdate);
        for (var key in this.batch) {
          formdata.set(key, this.batch[key]);
        }
        ApiService.post(this.VUE_APP_CERTIFICATION_STATUS_API, formdata)
          .then((response) => {
            this.load = false;

            if (response?.status == 200) {
              if (response.data.status == 'success') {
                Swal.fire({
                  title: 'Success!',
                  text: response.data.data,
                  icon: 'success',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => {
                  this.batch = {
                    entity_id: '',
                    tranche_id: '',
                    institute_id: '',
                    course_id: '',
                    batch_id: '',
                  };
                  this.emitter.emit('certification_updated', true);
                });
              }
              if (response.data.status == 'error') {
                Swal.fire({
                  title: 'Error!',
                  text: response.data.message,
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Close',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                });
              }
            } else {
              let err = '';
              for (const field of Object.keys(response.data.errors)) {
                err += response.data.errors[field][0] + '<br>';
              }
              Swal.fire({
                title: response.data.errors,
                html: err,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
            }
          })
          .catch((response) => {
            this.load = false;
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          html: 'Please select trainee first.',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    async getEntityInfos() {
      this.loading = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get(
        this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
      )
        .then((response) => {
          this.loading = false;
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTranches() {
      this.loading = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.loading = false;
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async courseInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&tranche=' +
          this.batch.tranche_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async batchInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_BATCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&course_info_id=' +
          this.batch.course_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          this.batchList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTraineeList() {
      if (this.batch.batch_id) {
        this.loading = true;
        await ApiService.get(
          this.VUE_APP_TRAINEE_LIST_API +
            '?tranche_id=' +
            this.batch.tranche_id +
            '&entity_id=' +
            this.batch.entity_id +
            '&training_institute_id=' +
            this.batch.institute_id +
            '&course_info_id=' +
            this.batch.course_id +
            '&batch_info_id=' +
            this.batch.batch_id +
            '&active_status=1'
        )
          .then((response) => {
            this.loading = false;
            this.traineeList = [];
            this.checkedData = [];
            //this.traineeList = response.data.data;
            for (let i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].training_assessment) {
                if (
                  response.data.data[i].training_assessment.assessment_score ==
                    100 &&
                  !response.data.data[i].training_assessment.is_certification
                ) {
                  this.traineeList.push(response.data.data[i]);
                }
              }
            }

            this.showDataTable = true;
          })
          .catch((response) => {
            this.loading = false;
            console.log(response);
          });
      }
    },
  },
  setup() {
    const AssociationSchema = Yup.object().shape({
      //entity_name: Yup.string().required().label("Name"),
      //entity_short_name: Yup.string().required().label("Short Name"),
    });
    return {
      AssociationSchema,
    };
  },
});
